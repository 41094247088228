import React, {
  forwardRef, ForwardRefExoticComponent, memo, useMemo,
} from 'react';
import { IconProps } from './types';

import {
  Close,
  Plus,
  Settings,
  Bag,
  Circle,
  Copy,
  Menu,
  App,
  ArrowLeft,
  ArrowLeftSmall,
  ArrowRight,
  Check,
  CheckSmall,
  PlusSmall,
  Search,
  Info,
  InfoW,
  Clip,
  Trash,
  Dnd,
  Refresh,
  Cross,
  Triangle,
  CheckRounded,
  CloseRounded,
  CloseSmall,
  InfoLight,
  TrashBig,
  CopyBig,
  Disabled,
  Soon,
  LinkArrow,
  Minus,
  Multiple,
  App4,
} from './IconsList';

import { iconParams } from './helpers';

export const Icon: ForwardRefExoticComponent<IconProps> = memo(forwardRef(({
  name,
  height,
  width = 20,
  className,
  onClick,
}, ref?: React.Ref<SVGSVGElement>) => {
  const style = useMemo(() => {
    return (height || width)
      ? { ...(width ? { width: `${width}px`, minWidth: `${width}px` } : {}), height: `${height || width}px` }
      : undefined;
  }, [height, width]);

  const icons = useMemo(() => ({
    close: <Close />,
    plus: <Plus />,
    settings: <Settings />,
    bag: <Bag />,
    menu: <Menu />,
    app: <App />,
    copy: <Copy />,
    circle: <Circle />,
    arrow_left: <ArrowLeft />,
    arrow_left_small: <ArrowLeftSmall />,
    arrow_right: <ArrowRight />,
    check: <Check />,
    check_small: <CheckSmall />,
    plus_small: <PlusSmall />,
    search: <Search />,
    info: <Info />,
    info_w: <InfoW />,
    clip: <Clip />,
    trash: <Trash />,
    triangle: <Triangle />,
    dnd: <Dnd />,
    refresh: <Refresh />,
    cross: <Cross />,
    check_rounded: <CheckRounded />,
    close_rounded: <CloseRounded />,
    close_small: <CloseSmall />,
    info_light: <InfoLight />,
    trash_big: <TrashBig />,
    copy_big: <CopyBig />,
    disabled: <Disabled />,
    soon: <Soon />,
    link_arrow: <LinkArrow />,
    minus: <Minus />,
    multiple: <Multiple />,
    'app-4': <App4 />,
  }), []);

  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      ref={ref}
      onClick={onClick}
      {...iconParams[name]}
    >
      {icons[name]}
    </svg>
  );
}));
