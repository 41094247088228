import {
  createContext, useCallback, useMemo, useState, useContext, useRef,
} from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { useLazyCheckAuthTokenQuery } from 'generated/schemas/auth';
import { graphqlClient } from 'connectors/sp';
import { postLogin, postLogout } from 'connectors/server';
import { trackEvent } from 'lib/features/events/thunks';
import { selectedAddressSelector } from 'lib/features/wallet';
import { getAddressFromAuthorizationToken, getAuthorizationHeader, getAuthorizationToken } from 'common/utils';
import { Login } from 'components/Login';
import { checkTokenExpDate, INVALID_TOKEN_ERROR } from './helpers';
import { AuthContextType, Cb } from './types';

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }) => {
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const [show, setShow] = useState(false);
  const cbRef = useRef<Cb | null>(null);
  const dispatch = useAppDispatch();
  const [checkAuth] = useLazyCheckAuthTokenQuery();

  const checkAccessToken = useCallback((token: string) => {
    const addressFromToken = getAddressFromAuthorizationToken(token);
    if (selectedAddress && addressFromToken && selectedAddress !== addressFromToken) {
      return addressFromToken;
    }
    return '';
  }, [selectedAddress]);

  const login = useCallback(async (token: string) => {
    const validToken = checkTokenExpDate(token);
    if (validToken.error) {
      throw new Error(validToken.error);
    }
    const badAddressFromToken = checkAccessToken(token);
    if (badAddressFromToken) {
      throw new Error(
        `Access token connected to account ${badAddressFromToken}, please connect this account or use another access token.`,
      );
    }
    graphqlClient.setHeader('Authorization', getAuthorizationHeader(token));
    const response = await checkAuth();
    if (!response?.error) {
      await postLogin(token);
      setShow(false);
      cbRef.current?.();
    } else {
      throw new Error(INVALID_TOKEN_ERROR);
    }
  }, [checkAuth, checkAccessToken]);

  const loginWithEvents = useCallback(async (token: string) => {
    try {
      await login(token);
      dispatch(trackEvent({ eventType: 'access_token_submit', property: { result: 'success' } }));
    } catch (e) {
      dispatch(trackEvent({
        eventType: 'access_token_submit',
        property: { error: (e as Error)?.message, result: 'error', errorStack: (e as Error)?.stack },
      }));
      throw e;
    }
  }, [dispatch, login]);

  const logout = useCallback(async () => {
    await postLogout();
    graphqlClient.setHeader('Authorization', '');
  }, []);

  const showLogin = useCallback((cb?: Cb | null) => {
    cbRef.current = cb || null;
    setShow(true);
  }, []);

  const onClose = useCallback(() => {
    cbRef.current = null;
    setShow(false);
  }, []);

  const value = useMemo(() => ({
    showLogin, logout, checkAccessToken,
  }), [showLogin, logout, checkAccessToken]);

  return (
    <AuthContext.Provider value={value}>
      <Login show={show} onClose={onClose} login={loginWithEvents} />
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(cb?: Cb | null) {
  const { showLogin, checkAccessToken, ...rest } = useContext(AuthContext);
  const runEvent = useCallback(async () => {
    if (!cb) return;
    const tokenFromStorage = getAuthorizationToken();
    if (!tokenFromStorage || checkAccessToken(tokenFromStorage)) {
      return showLogin(cb);
    }
    const response = await cb();
    if (response?.error && response?.payload === 'Unauthorized') {
      return showLogin(cb);
    }
  }, [cb, showLogin, checkAccessToken]);

  return {
    ...rest, runEvent, showLogin, checkAccessToken,
  };
}