import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import {
  isSSR, getAuthorizationHeader, getAuthorizationToken, JSONParseSafe,
} from 'common/utils';
import getConfig from 'config';

const customFetch = (input: RequestInfo | URL, init?: RequestInit) => fetch(
  `${input}${typeof init?.body === 'string' ? `?op=${JSONParseSafe(init.body)?.operationName || null}` : ''}`,
  init,
);

export const graphqlClient = new GraphQLClient(
  getConfig().NEXT_PUBLIC_API_ENDPOINT,
  {
    fetch: customFetch,
    requestMiddleware: (request) => {
      if (!isSSR()) {
        return {
          ...request,
          headers: {
            Authorization: getAuthorizationHeader(getAuthorizationToken()),
            ...request.headers,
          },
        };
      }
      return request;
    },
    errorPolicy: 'all',
    headers: {
      'x-sdk-version': getConfig().NEXT_PUBLIC_SDK_VERSION,
    },
  },
);

export const getBaseQuery = () => graphqlRequestBaseQuery({
  client: graphqlClient as any,
}); // todo

export const api = createApi({
  baseQuery: getBaseQuery(),
  endpoints: () => ({}),
});
