import { createContext } from 'react';
import { FilesContextProps } from './types';

export const FilesContext = createContext<FilesContextProps>({
  files: {},
  onChangeFiles: () => {},
  onDeleteFile: () => {},
  reset: () => {},
});

export const FilesContextProvider = FilesContext.Provider;
