import { FC, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { ToastContainer } from 'uikit/Toast/ToastContainer';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { useWindowSize } from 'common/hooks/useWindowSize';
import { FilesProvider } from 'common/contexts/Files';
import { AuthProvider } from 'common/contexts/Auth/AuthContext';
import { setBodyTheme } from './helpers';
import { AppWrapProps } from './types';

const Wallet = dynamic(() => import('../Wallet/Wallet'), { ssr: false });

export const AppWrap: FC<AppWrapProps> = ({ children }) => {
  const theme = useAppSelector(themeSelector);
  useWindowSize();

  useEffect(() => {
    setBodyTheme(theme);
  }, [theme]);

  return (
    <AuthProvider>
      <FilesProvider>
        <Wallet />
        {children}
      </FilesProvider>
      <ToastContainer theme={theme} />
    </AuthProvider>
  );
};