export interface LoginProps {
  show: boolean;
  onClose: () => void;
  login: (token: string) => Promise<void>;
}

export enum Fields {
  token = 'token'
}

export interface FormValues {
  [Fields.token]: string;
}
