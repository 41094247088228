import { getWeb3InstanceByWalletType } from 'common/hooks/useWeb3Provider';
import { SelectedWalletType } from '../wallet/types';
import { SecretKeeperState } from './types';

export const getInitialState = (): SecretKeeperState => ({});

export const createSignature = async (selectedWalletType: SelectedWalletType, nonce: string, account: string) => {
  return (await getWeb3InstanceByWalletType(selectedWalletType))!.eth.personal.sign(
    `I am signing my one-time nonce: ${nonce}`,
    account,
    '', // MetaMask will ignore the password argument here
  );
};
