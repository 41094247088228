import { fromWei } from 'web3-utils';
import { BigNumber } from 'bignumber.js';
import { OfferType, OrderStatus } from '@super-protocol/sdk-js';
import {
  TOfferType,
} from 'generated/types';
import { ErrorWithOriginal } from 'common/utils';

export interface GetFixedProps {
  deposit?: BigNumber | string | null;
  wei?: boolean;
  count?: number;
}

export const getFixedDeposit = (props: GetFixedProps): string => {
  const {
    deposit,
    count = 6,
    wei = true,
  } = props || {};
  const DEFAULT_VALUE = 0;
  if (!deposit) return `${DEFAULT_VALUE}`;
  const depositStr = new BigNumber(deposit).toFixed();
  if (depositStr === 'NaN') return `${DEFAULT_VALUE}`;
  const bn = new BigNumber(wei ? fromWei(depositStr, 'ether') : depositStr);
  return bn.toFixed().split('.')[1]?.length > count ? bn.toFixed(count, 1) : bn.toFixed();
};

export const getMultipliedDeposit = (deposit: string | number | BigNumber | null, multiply: number): BigNumber | null => {
  if (!deposit) return null;
  const bg = deposit instanceof BigNumber ? deposit : new BigNumber(deposit);
  return bg.multipliedBy(multiply);
};

export const getSumDeposit = (
  deposit?: string | BigNumber | null,
  sum?: string | BigNumber | number | null,
): BigNumber | null => {
  if (!deposit && !sum) return null;
  return (deposit instanceof BigNumber ? deposit : new BigNumber(deposit || 0)).plus(sum || 0);
};

export const getOrdersDeposit = (list?: (string | BigNumber)[]): BigNumber => {
  if (!list?.length) return new BigNumber(0);
  return BigNumber.sum.apply(null, list);
};

export function getEnumName(value: string, en: { [key: string]: string | number }): string {
  if (!value) return '';
  return Object.entries(en).find(([, val]) => value === val)?.[0] ?? '';
}

export const getOfferTypeName = (offerType: OfferType): string => getEnumName(offerType, OfferType);

export const getOrderStatusName = (status: OrderStatus): string => getEnumName(status, OrderStatus);

export const getTOfferTypeFromOfferType = (offerType: OfferType): TOfferType | undefined => {
  switch (offerType) {
    case OfferType.Data:
      return TOfferType.Data;
    case OfferType.Solution:
      return TOfferType.Solution;
    case OfferType.Storage:
      return TOfferType.Storage;
    case OfferType.TeeOffer:
      return TOfferType.TeeOffer;
    default:
      return undefined;
  }
};

export const getErrorMessage = (
  error?: Error | ErrorWithOriginal<{ data: { message: string } } | Error | string>,
):
   null | string => {
  if (!error) return null;
  if (typeof error === 'string') return error;
  return (error as ErrorWithOriginal<{ data: { message: string } }>).originalError?.data?.message
    || (error as ErrorWithOriginal<Error>)?.originalError?.message
    || error?.message
    || null;
};
