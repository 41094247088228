import {
  memo, FC, useState, useCallback, useMemo, useEffect,
} from 'react';
import mergeWith from 'lodash.mergewith';
import { Provider } from 'react-redux';
import { createStoreClient, storages } from 'lib/store';
import { HYDRATE_STORE } from 'lib/features/helpers';
import { isSSR } from 'common/utils';
import { Storages } from 'lib/features/types';
import { PersistGateProps } from './types';

export const PersistGate: FC<PersistGateProps> = memo(({ children, serverState, hydrate }) => {
  const filteredState = useMemo(() => {
    if (!serverState) return {};
    return Object
      .entries(storages)
      .reduce((acc, [reducerName, storage]) => {
        // restore only cookie storage from server. other storages hydrate on the client
        if (storage === Storages.cookie && serverState[reducerName]) {
          return { ...acc, [reducerName]: serverState[reducerName] };
        }
        return acc;
      }, {});
  }, [serverState]);
  const [bootstraped, setBootstraped] = useState<boolean>(false);
  const cb = useCallback(() => {
    setBootstraped(true);
  }, []);
  // merge server state with new hydrated state
  const preloadedState = useMemo(() => mergeWith(filteredState, hydrate), [filteredState, hydrate]);
  const store = useMemo(() => createStoreClient({ preloadedState, cb }), [cb, preloadedState]);

  useEffect(() => {
    if (bootstraped && !isSSR()) {
      // hydrate the store if necessary
      store.dispatch({ type: HYDRATE_STORE, payload: hydrate });
    }
  }, [hydrate, bootstraped, store]);

  return (
    <Provider store={store} key={`${bootstraped}`}>
      {children}
    </Provider>
  );
});