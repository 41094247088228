const api = async (input: RequestInfo | URL, init?: RequestInit) => fetch(
  input,
  {
    ...init,
    headers: {
      'Content-Type': 'application/json',
    },
  },
);

export const postLogin = (token: string) => {
  return api('/api/login', {
    method: 'post',
    body: JSON.stringify({ token }),
  });
};

export const postLogout = () => {
  return api('/api/logout', {
    method: 'post',
    body: JSON.stringify({}),
  });
};

export const postUploadFileS3 = (body: FormData) => {
  return fetch('/api/upload-file-s3', {
    method: 'post',
    body,
  });
};

export const getDownloadFileS3 = (props: { fileName: string; bucket: string; }) => {
  return api(
    `/api/download-file-s3?${new URLSearchParams(props as unknown as Record<string, string>)}`,
  );
};