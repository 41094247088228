import sum from 'lodash.sum';
import { OfferType } from '@super-protocol/sdk-js';
import {
  TeeOffer,
  Offer,
  TOfferType,
  TeeOffersAndSLots,
  WorkflowConfigurationValidation,
  ValueOfferWithSlotsAndOptions,
  TeeOfferWithSlotsAndOptions,
  OfferSlot,
  TeeOfferSlot,
  OfferRestrictions,
} from 'generated/types';
import { Modes } from 'uikit/MnemonicGenerator/MnemonicGeneratorUi/types';
import { generateMnemonic } from 'common/utils/crypto';
import { getRestrictionsByTOfferType } from 'common/utils';
import BlockchainConnector from 'connectors/sdk/BlockchainConnector';
import {
  Status,
  Steps,
  Item,
  Form,
  BuildOrderForm,
  FieldsBuildOrderForm,
  FormOffer,
  CheckAddOffer,
  CreatePassphraseForm,
  FieldsCreatePassphraseForm,
  Process,
  ProcessType,
  ProcessStatus,
  FormOffers,
  GetExtendedFormOffersProps,
  CreateOrderState,
  CheckConflictResult,
  FormOfferKey,
  IsDataInFormOffersResult,
  ErrorIsDataInFormOffers,
  Slots,
  OfferRestrictionWithType,
} from './types';

// we don't use count for offer's slots. set 1 for price calculaction
export enum SLOT_COUNT {
  TEE_OFFER = 1,
  VALUE_OFFER = 0,
  PREVIEW = 1, // for value offers preview
}

export const getInitialSteps = (): Item[] => [
  { status: Status.ACTIVE, value: Steps.BUILD_ORDER },
  { status: Status.DISABLED, value: Steps.CREATE_PASSPHRASE },
  { status: Status.DISABLED, value: Steps.CONFIRM_TRANSACTIONS },
];

export const getInitialBuildOrderForm = (): BuildOrderForm => ({
  [FieldsBuildOrderForm.solution]: null,
  [FieldsBuildOrderForm.tee]: null,
  [FieldsBuildOrderForm.storage]: null,
  [FieldsBuildOrderForm.data]: [],
  [FieldsBuildOrderForm.file]: null,
  [FieldsBuildOrderForm.deposit]: '',
});

export const getInitialCreatePassphraseForm = (): CreatePassphraseForm => ({
  [FieldsCreatePassphraseForm.phraseGenerated]: generateMnemonic(),
  [FieldsCreatePassphraseForm.phraseInput]: '',
  [FieldsCreatePassphraseForm.phraseMode]: Modes.own,
});

export const getInitialForm = (): Form => ({
  [Steps.BUILD_ORDER]: getInitialBuildOrderForm(),
  [Steps.CREATE_PASSPHRASE]: getInitialCreatePassphraseForm(),
  [Steps.CONFIRM_TRANSACTIONS]: {},
});

export const getOfferFormIds = (offerForm: FormOffer | FormOffer[] | undefined | null, skipIfHasData?: boolean): string[] => {
  if (!offerForm) return [];
  return (Array.isArray(offerForm) ? offerForm : [offerForm]).reduce((acc, item) => {
    const baseIds: string[] = (item.base || [])
      .filter((item) => !!item?.value && (!skipIfHasData || !item?.data))
      .map((item) => item?.value as string);
    return [...acc, ...baseIds].concat(item?.value && (!skipIfHasData || !item?.data) ? [item.value] : []);
  }, [] as string[]);
};

export const getFormBuildOrderIds = (buildOrderForm: BuildOrderForm): string[] => {
  if (!buildOrderForm) return [];
  return Object.values(buildOrderForm).reduce((acc, offerForm) => [...acc, ...getOfferFormIds(offerForm)], []);
};

export const getFormBuildOrdersCount = (buildOrderForm: BuildOrderForm): number => {
  const ids = getFormBuildOrderIds(buildOrderForm);
  return (ids.length || 0) + (buildOrderForm?.[FieldsBuildOrderForm.file] ? 1 : 0);
};

export const checkAddValueOffer = (
  id: string,
  buildOrderForm?: BuildOrderForm,
  fieldsBuildOrderForm?: FieldsBuildOrderForm.data
   | FieldsBuildOrderForm.solution
   | FieldsBuildOrderForm.storage,
): CheckAddOffer => {
  if (!fieldsBuildOrderForm) {
    return {
      isAdded: false,
    };
  }
  const offerForm = buildOrderForm?.[fieldsBuildOrderForm];
  const ids = getOfferFormIds(offerForm);
  return {
    isAdded: ids.includes(id),
  };
};

export const getSlotsFromTeeOffersAndSLots = (data?: TeeOffersAndSLots): Slots | null => {
  if (!data) return null;
  const { slotResult, optionsResult } = data || {};
  const { optionResults } = optionsResult || {};
  const { multiplier, slot } = slotResult || {};
  const { id } = slot || {};
  return {
    slot: {
      id,
      count: multiplier,
    },
    options: (optionResults || []).map(({ id, count }) => ({ id, count })),
  };
};

export const checkAddTeeOffer = (
  offerId: string,
  buildOrderForm?: BuildOrderForm,
  fieldsBuildOrderForm?: FieldsBuildOrderForm.tee,
  slots?: Slots | null,
): CheckAddOffer => {
  if (!fieldsBuildOrderForm || !slots) {
    return {
      isAdded: false,
    };
  }
  const { slot, options } = slots || {};
  const { id: slotId, count: slotCount } = slot || {};
  const offerForm = buildOrderForm?.[fieldsBuildOrderForm] as FormOffer;
  const { slots: slotsFromOfferForm, value: offerIdFromOfferForm } = offerForm || {};
  const { slot: slotFromOfferForm, options: optionsFromOfferForm } = slotsFromOfferForm || {};
  const { id: slotIdFromOfferForm, count: slotCountFromOfferForm } = slotFromOfferForm || {};
  return {
    isAdded: !!(
      offerId === offerIdFromOfferForm
      && slotIdFromOfferForm === slotId
      && slotCountFromOfferForm === slotCount
      && (
        !optionsFromOfferForm?.length
        || optionsFromOfferForm?.every(({ id, count }) => options?.find((option) => option?.id === id && option?.count === count))
      )
    ),
  };
};

export const checkAddOffer = (
  id: string,
  buildOrderForm?: BuildOrderForm,
  fieldsBuildOrderForm?: FieldsBuildOrderForm.data
   | FieldsBuildOrderForm.solution
   | FieldsBuildOrderForm.storage
   | FieldsBuildOrderForm.tee,
  slots?: Slots | null,
): CheckAddOffer => {
  switch (fieldsBuildOrderForm) {
    case FieldsBuildOrderForm.tee:
      return checkAddTeeOffer(id, buildOrderForm, fieldsBuildOrderForm, slots);
    default:
      return checkAddValueOffer(id, buildOrderForm, fieldsBuildOrderForm);
  }
};

export const getFielBuildOrderdByOfferType = (offerType?: OfferType): FieldsBuildOrderForm.solution
 | FieldsBuildOrderForm.data
 | FieldsBuildOrderForm.storage
 | FieldsBuildOrderForm.tee
 | undefined => {
  switch (offerType) {
    case OfferType.Data:
      return FieldsBuildOrderForm.data;
    case OfferType.Solution:
      return FieldsBuildOrderForm.solution;
    case OfferType.Storage:
      return FieldsBuildOrderForm.storage;
    case OfferType.TeeOffer:
      return FieldsBuildOrderForm.tee;
    default:
      return undefined;
  }
};

export const getProcessList = (form: Form): ProcessType[] => {
  const { tee, file } = form?.[Steps.BUILD_ORDER] || {};
  return ([] as ProcessType[])
    .concat((tee ? [ProcessType.WORKFLOW, ProcessType.WORKFLOW] : []))
    .concat(file ? ProcessType.FILE : []);
};

export const getInitialProcess = (processList: ProcessType[]): Process => {
  return processList.reduce((acc, process) => ({ ...acc, [process]: { status: ProcessStatus.QUEUE } }), {});
};

export const getOffersAndTeeOffersIds = (formOffers: FormOffers | undefined, skipIfHasData = true): {
  offers: string[],
  teeOffers: string[],
} => {
  if (!formOffers) return { offers: [], teeOffers: [] };
  const {
    tee, data, storage, solution,
  } = formOffers;
  return {
    teeOffers: getOfferFormIds(tee, skipIfHasData),
    offers: [data, storage, solution].map((offerForm) => getOfferFormIds(offerForm, skipIfHasData)).flat(),
  };
};

export const getOfferWithSlotId = (formOffer?: FormOffer | null): string[] => {
  if (!formOffer) return [];
  const { value, slots } = formOffer || {};
  const { slot } = slots || {};
  const { id: slotId } = slot || {};
  if (value && slotId) return [value, slotId];
  return [];
};

export const getOffersWithSlotId = (formOffer?: FormOffer | FormOffer[] | null): string[] => {
  if (!formOffer) return [];
  if (Array.isArray(formOffer)) {
    return formOffer.map(getOfferWithSlotId).flat();
  }
  return getOfferWithSlotId(formOffer);
};

export const getOffersWithSlotsIds = (formOffers: FormOffers | undefined): string[][] => {
  if (!formOffers) return [];
  const {
    data, storage, solution,
  } = formOffers;
  return [...(data || []), storage, solution].map(getOffersWithSlotId).filter((v) => !!v?.length);
};

export const getOrderMinDeposit = async (offer?: FormOffer): Promise<number> => {
  if (offer) {
    const Web3 = (await import('web3')).default;
    const res = Number(
      Web3.utils.fromWei(
        await BlockchainConnector.getInstance().getOfferHoldSum(offer.value as string, offer.slots as Slots),
        'ether',
      ),
    );
    return res;
  }
  return 0;
};

export const getOrderMinDepositWithBase = async (offerForm?: FormOffer): Promise<number> => {
  const value = await getOrderMinDeposit(offerForm);
  if (offerForm?.base?.length) {
    const ret = await (offerForm.base).reduce(async (promise, offer) => {
      return promise.then(async (last) => {
        const res = await getOrderMinDeposit(offer);
        const ret = last + res;
        return ret;
      });
    }, Promise.resolve(value));
    return ret;
  }
  return value;
};

export const getCalcOrderDeposit = async (
  offer: FormOffer | undefined | null,
  orderMinDeposit: number,
  type: OfferType,
): Promise<number> => {
  if (!offer) return 0;
  switch (type) {
    case OfferType.Storage:
    case OfferType.Solution:
    case OfferType.Data: {
      const res = await getOrderMinDepositWithBase(offer);
      return res;
    }
    case OfferType.TeeOffer:
      return orderMinDeposit;
    default:
      return 0;
  }
};

export const getCalcOrderDepositSum = async (
  offers: FormOffer[],
  orderMinDeposit: number,
  type: OfferType,
): Promise<number> => {
  if (!offers || !Array.isArray(offers) || !offers.length) return 0;
  return sum(await Promise.all(offers.map(async (offer) => {
    const res = await getCalcOrderDeposit(offer, orderMinDeposit, type);
    return res;
  })));
};

export const getMinDepositWorkflow = async (formOffers: FormOffers, orderMinDeposit: number): Promise<number> => {
  const {
    data = [],
    solution,
    tee,
    storage,
  } = formOffers || {};
  return Math.max(
    sum([
      await getCalcOrderDepositSum(data, orderMinDeposit, OfferType.Data),
      await getCalcOrderDeposit(solution, orderMinDeposit, OfferType.Solution),
      await getCalcOrderDeposit(tee, orderMinDeposit, OfferType.TeeOffer),
      await getCalcOrderDeposit(storage, orderMinDeposit, OfferType.Storage),
    ]),
    orderMinDeposit,
  );
};

export const getExtendOfferForm = (
  offerForm: FormOffer | undefined | null,
  offers: (Offer | TeeOffer | undefined | null)[] | undefined,
): FormOffer | null => {
  if (!offerForm?.value) return null;
  return {
    ...offerForm,
    data: offerForm?.data ? offerForm.data : (offers || []).find((offer) => offerForm?.value === offer?.id),
    base: offerForm?.base?.length
      ? offerForm.base.map((offerForm) => getExtendOfferForm(offerForm, offers) as FormOffer).filter((offerForm) => !!offerForm)
      : [],
  };
};

export const getOfferFormFromExtend = (offerForm: FormOffer | undefined | null): FormOffer | null => {
  if (!offerForm?.value) return null;
  return {
    ...offerForm,
    data: null,
    base: offerForm?.base?.length
      ? offerForm.base.map((offerForm) => getOfferFormFromExtend(offerForm) as FormOffer).filter((offerForm) => !!offerForm)
      : [],
  };
};

export const getExtendedFormOffers = (props: GetExtendedFormOffersProps): FormOffers => {
  const { formOffers, offers, teeOffers } = props || {};
  const {
    tee, storage, data, solution,
  } = formOffers || {};
  return {
    [FieldsBuildOrderForm.solution]: getExtendOfferForm(solution, offers),
    [FieldsBuildOrderForm.tee]: getExtendOfferForm(tee, teeOffers),
    [FieldsBuildOrderForm.storage]: getExtendOfferForm(storage, offers),
    [FieldsBuildOrderForm.data]: (data || [])
      .map((offerForm) => getExtendOfferForm(offerForm, offers) as FormOffer)
      .filter((offerForm) => !!offerForm),
  };
};

export const getInitialState = (): CreateOrderState => ({
  steps: getInitialSteps(),
  form: getInitialForm(),
  process: getInitialProcess([]),
  submitLoading: false,
});

export const getRestrictionsWithType = (restrictions?: OfferRestrictions | null): OfferRestrictionWithType[] => {
  if (!restrictions) return [];
  const { offers, types } = restrictions || {};
  return (offers || []).map((offer, index) => ({ offer, type: types?.[index] as OfferType || null }));
};

export const getRestrictionsOffers = (
  offer: Offer | null | undefined,
  base: Offer[] | undefined | null,
): OfferRestrictionWithType[] => {
  const mainRestrictionsOffers = getRestrictionsWithType(offer?.offerInfo?.restrictions);
  const baseRestrictionsOffers = base?.length
    ? base.map((offer) => getRestrictionsWithType(offer?.offerInfo?.restrictions)).flat()
    : [];
  return [...mainRestrictionsOffers, ...baseRestrictionsOffers];
};

export const getRestrictionsFormOffer = (formOffer?: FormOffer | null): OfferRestrictionWithType[] => {
  const { data, base } = formOffer || {};
  return getRestrictionsOffers(
    data as Offer,
    base?.map((formOffer) => formOffer?.data as Offer),
  );
};

const isOfferCompatibleWith = (offer?: FormOffer | null, offers?: (FormOffer | null | undefined)[] | null): boolean => {
  const offerRestrictions = getRestrictionsFormOffer(offer);
  if (!offerRestrictions?.length) return true;
  return !!offerRestrictions
    .every(
      ({ offer }) => (offers || [])
        .some((formOffer) => formOffer?.value === offer),
    );
};

export const checkConflictSolution = (
  extendedFormOffer: FormOffer | null,
  extendedFormOffers: FormOffers,
): CheckConflictResult => {
  const solutionFormOffers = [extendedFormOffers?.solution, ...(extendedFormOffers?.solution?.base || [])];
  return {
    [FieldsBuildOrderForm.solution]: extendedFormOffers[FieldsBuildOrderForm.solution]?.value
      && extendedFormOffer?.base?.[0]?.value !== extendedFormOffers?.[FieldsBuildOrderForm.solution]?.value
      ? extendedFormOffers[FieldsBuildOrderForm.solution]
      : null,
    [FieldsBuildOrderForm.data]: extendedFormOffers[FieldsBuildOrderForm.data]
      ?.filter((formOffer) => !isOfferCompatibleWith(formOffer, solutionFormOffers)),
  };
};

export const checkConflictStorage = (formOffer: FormOffer | null, formOffers: FormOffers): CheckConflictResult => {
  return {
    [FieldsBuildOrderForm.storage]: formOffers[FieldsBuildOrderForm.storage],
  };
};

export const checkConflictTee = (formOffer: FormOffer | null, formOffers: FormOffers): CheckConflictResult => {
  return {
    [FieldsBuildOrderForm.tee]: formOffers[FieldsBuildOrderForm.tee],
  };
};

export const checkConflictData = (formOffer: FormOffer | null, formOffers: FormOffers): CheckConflictResult => {
  const { solution } = formOffers;
  const solutionFormOffers = [solution, ...(solution?.base || [])];
  return {
    [FieldsBuildOrderForm.solution]: !isOfferCompatibleWith(formOffer, solutionFormOffers)
     || !solutionFormOffers.some((offer) => !isOfferCompatibleWith(offer, [formOffer]))
      ? solution
      : null,
  };
};

export const checkConflict = (
  field: FormOfferKey,
  formOffer: FormOffer | null,
  extendedFormOffers: FormOffers,
): CheckConflictResult => {
  switch (field) {
    case FieldsBuildOrderForm.solution:
      return checkConflictSolution(formOffer, extendedFormOffers);
    case FieldsBuildOrderForm.storage:
      return checkConflictStorage(formOffer, extendedFormOffers);
    case FieldsBuildOrderForm.tee:
      return checkConflictTee(formOffer, extendedFormOffers);
    case FieldsBuildOrderForm.data:
      return checkConflictData(formOffer, extendedFormOffers);
    default:
      return {};
  }
};

export const isHasConflict = (conflict: CheckConflictResult) => {
  return Object
    .values(conflict || {})
    .some((offerForm) => (Array.isArray(offerForm) ? offerForm?.length : offerForm));
};

export const getBaseOffers = (offer?: Offer | TeeOffer | null): string[] => {
  const { offerType } = (offer as Offer)?.offerInfo || {};
  if (offerType) {
    return getRestrictionsByTOfferType(
      (offer as Offer)?.offerInfo?.restrictions,
      offerType as OfferType,
    );
  }
  return [];
};

export const isDataInFormOffer = (formOffer: FormOffer | null) => !formOffer?.value || !!formOffer?.data;

export const isDataInFormOfferOrFormOfferList = (formOffer: null | FormOffer | FormOffer[]) => {
  if (Array.isArray(formOffer)) {
    return formOffer.every(isDataInFormOffer);
  }
  return isDataInFormOffer(formOffer);
};

export const dataInFormOffers = (formOffers: FormOffers): IsDataInFormOffersResult => Object
  .entries(formOffers).reduce((acc, [key, value]) => ({ ...acc, [key]: isDataInFormOfferOrFormOfferList(value) }), {});

export const isDataInFormOffers = (formOffers: FormOffers): boolean => Object
  .values(dataInFormOffers(formOffers))
  .every((value) => !!value);

export const errorIsDataInFormOffers = (formOffers: FormOffers): ErrorIsDataInFormOffers => Object
  .entries(dataInFormOffers(formOffers))
  .reduce((acc, [key, value]) => ({ ...acc, [key]: !value ? 'Offer information is empty' : '' }), {});

export const getIsTeeOfferByFormOfferKey = (formOfferKey: FormOfferKey) => formOfferKey === FieldsBuildOrderForm.tee;

export const fieldsBuildOrderFormByTOfferType = {
  [TOfferType.Data]: FieldsBuildOrderForm.data,
  [TOfferType.Storage]: FieldsBuildOrderForm.storage,
  [TOfferType.TeeOffer]: FieldsBuildOrderForm.tee,
  [TOfferType.Solution]: FieldsBuildOrderForm.solution,
};

export const fieldsBuildOrderFormByOfferType = {
  [OfferType.Solution]: FieldsBuildOrderForm.solution,
  [OfferType.Data]: FieldsBuildOrderForm.data,
  [OfferType.Storage]: FieldsBuildOrderForm.storage,
  [OfferType.TeeOffer]: FieldsBuildOrderForm.tee,
};

export const getOffersRestrictions = (data): string[] => {
  const ids: string[] = data.map((item) => (item?.node?.offerInfo?.restrictions?.offers || [])).flat();
  return [...new Set(ids)];
};

export const getIsOfferAlreadyAdded = (
  offerId: string | undefined,
  field: FieldsBuildOrderForm | undefined,
  formOffers?: FormOffers,
): boolean => {
  if (!field || !formOffers || !offerId) return false;
  const formOffer = formOffers[field];
  if (Array.isArray(formOffer)) {
    return !!formOffer.find(({ value }) => value === offerId);
  }
  return formOffer?.value === offerId;
};

export const getSelectedSlotsByOfferId = (
  offerId: string | undefined,
  field: FieldsBuildOrderForm | undefined,
  formOffers?: FormOffers,
): Slots | null => {
  if (!field || !formOffers || !offerId) return null;
  const formOffer = formOffers[field];
  if (Array.isArray(formOffer)) {
    return formOffer.find(({ value }) => value === offerId)?.slots || null;
  }
  if (formOffer?.value !== offerId) return null;
  return formOffer?.slots || null;
};

export const getPreparedSlots = (data: TeeOffersAndSLots | Offer): Slots => {
  if (!data) return {};
  if (!(data as TeeOffersAndSLots)?.teeOffer) return {}; // needs only TeeOffersAndSLots
  const { slotResult, optionsResult } = data as TeeOffersAndSLots || {};
  const { slot, multiplier } = slotResult || {};
  const { optionResults } = optionsResult || {};
  return {
    ...(slot?.id ? {
      slot: {
        id: slot.id,
        count: multiplier,
      },
    } : {}),
    ...(optionResults?.length ? { options: optionResults.map(({ id, count }) => ({ id, count })) } : {}),
  };
};

export const getValueOfferWithSlotsAndOptionsFromFormOffer = (formOffer: FormOffer): ValueOfferWithSlotsAndOptions => {
  const { value, slots } = formOffer || {};
  const { slot } = slots || {};
  const { id: slotId } = slot || {};
  return {
    offerId: value ?? '',
    slot: {
      id: slotId ?? '',
    },
  };
};

export const getTeeOfferWithSlotsAndOptionsFromFormOffer = (formOffer: FormOffer): TeeOfferWithSlotsAndOptions => {
  const { value, slots } = formOffer || {};
  const { slot, options = [] } = slots || {};
  const { id: slotId, count: slotCount } = slot || {};
  return {
    offerId: value ?? '',
    slot: {
      id: slotId ?? '',
      count: slotCount ?? 0,
    },
    options: options ?? [],
  };
};

export const getWorkflowConfigurationValidationFromBuildOrderForm = (
  buildOrderForm: BuildOrderForm,
  minTimeMinutes = 0,
): WorkflowConfigurationValidation => {
  const {
    solution, storage, tee, data,
  } = buildOrderForm;
  return {
    solution: solution
      ? [
        getValueOfferWithSlotsAndOptionsFromFormOffer(solution as FormOffer),
        ...(solution.base ?? []).map((formOfferBase) => getValueOfferWithSlotsAndOptionsFromFormOffer(formOfferBase))]
      : [],
    storage: getValueOfferWithSlotsAndOptionsFromFormOffer(storage as FormOffer),
    data: (data ?? []).map((formOffer) => getValueOfferWithSlotsAndOptionsFromFormOffer(formOffer as FormOffer)),
    tee: getTeeOfferWithSlotsAndOptionsFromFormOffer(tee as FormOffer),
    minTimeMinutes,
  };
};

export const getMaxUsageMinTimeMinutesFromFormOffer = (formOffer: FormOffer | null): number => {
  if (!formOffer) return 0;
  const { slots, data } = formOffer;
  return Math.max(
    ...((data?.slots ?? []) as Array<OfferSlot | TeeOfferSlot>)
      .filter(({ id }) => slots?.slot?.id === id)
      .map(({ usage }) => usage?.minTimeMinutes),
  );
};

export const getMaxUsageMinTimeMinutesFromExtendedBuildOrderForm = (buildOrderForm: BuildOrderForm): number => {
  if (!buildOrderForm) return 0;
  const {
    solution, storage, tee, data,
  } = buildOrderForm;
  return Math.max(
    getMaxUsageMinTimeMinutesFromFormOffer(solution),
    getMaxUsageMinTimeMinutesFromFormOffer(storage),
    getMaxUsageMinTimeMinutesFromFormOffer(tee),
    ...data.map(getMaxUsageMinTimeMinutesFromFormOffer),
  );
};