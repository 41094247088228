import {
  createSlice,
} from '@reduxjs/toolkit';
import { OffersPages } from 'common/types/pages';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions, hydrate } from 'lib/features/helpers';
import { FiltersState, Form, Blocks } from './types';
import { Storage } from '../types';
import { getInitialState, getResetData } from './helpers';
import migrations from './migrations';

const initialState: FiltersState = getInitialState();

const NAME = 'filters';

export const filters = createSlice({
  name: NAME,
  initialState,
  reducers: {
    updatePageFilters(state, action: PayloadAction<{ page: OffersPages, filters: Form }>) {
      const { page, filters } = action.payload || {};
      if (page && filters) {
        state[page] = filters;
      }
    },
    resetPageFilters(state, action: PayloadAction<{ page: OffersPages, filters: Form, block?: Blocks }>) {
      const { page, block, filters } = action.payload || {};
      if (page) {
        state[page] = getResetData(filters, { active: page, block });
      }
    },
  },
  extraReducers(builder) {
    hydrate(builder, NAME);
  },
  selectors: {
    filtersSelector: (state) => (state),
  },
});

export const {
  updatePageFilters,
  resetPageFilters,
} = filters.actions;

export const {
  filtersSelector,
} = filters.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'filters', storage, migrate: migrations, version: 3,
}, filters.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(filters);