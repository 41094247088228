import { NextApiRequest, NextApiResponse } from 'next';
import { createStoreServer } from 'lib/store';

export const getInitialReduxState = () => async (props: { ctx: { req: NextApiRequest, res: NextApiResponse } }) => {
  const { ctx } = props || {};
  const { req, res } = ctx || {};
  const store = await createStoreServer({ req, res });
  return {
    initialProps: {
      state: store.getState() || {},
    },
  };
};
