import { parseJwt } from 'common/utils';
import { Decoded, ValidateToken } from './types';

export const INVALID_TOKEN_ERROR = 'Invalid token';
export const DEFAULT_TOKEN_ERROR = 'Incorrect token';

export const checkTokenExpDate = (token: string): ValidateToken => {
  const res: ValidateToken = {
    token,
    error: undefined,
  };
  try {
    const decoded = parseJwt(token) as Decoded;
    const { exp } = decoded || {};
    if (exp) {
      const now = new Date().getTime();
      if (now > exp * 1000) {
        res.error = 'Token has expired, please request a new one';
      }
    } else {
      res.error = DEFAULT_TOKEN_ERROR;
    }
  } catch (e) {
    res.error = DEFAULT_TOKEN_ERROR;
  }

  return res;
};
