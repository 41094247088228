import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { getInitialReduxState } from 'common/getInitialProps/reduxState';
import { AppWrap } from 'components/AppWrap';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Fonts } from 'common/components/Fonts/Fonts';
import { initLogger } from 'services/logger';
import { RootState, AppStore } from 'lib/store';
import { PersistGate } from 'common/components/PersistGate';
import 'common/styles/index.scss';

const progressOptions = { showSpinner: false };

export interface MyAppProps extends AppProps<{ hydrate?: Partial<AppStore> }> {
  initialProps: {
    state: RootState;
  }
}

function MyApp({ Component, pageProps, initialProps }: MyAppProps) {
  initLogger();
  return (
    <ErrorBoundary>
      <Fonts />
      <PersistGate serverState={initialProps?.state} hydrate={pageProps?.hydrate}>
        <NextNProgress options={progressOptions} />
        <AppWrap>
          <Component {...pageProps} />
        </AppWrap>
      </PersistGate>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = getInitialReduxState();

export default MyApp;
