// format: 1,2,4,5,22,3,1
const getObjFromStringEnvVariable = (str?: string) => {
  return (str?.split(',') || []).reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {} as { [key: string]: boolean });
};

export interface Config<Prepared = any> {
  NEXT_PUBLIC_API_ENDPOINT: string | Prepared;
  NEXT_PUBLIC_API_ENDPOINT_WSS: string | Prepared;
  NEXT_PUBLIC_NETWORK_CHAIN_ID: number | Prepared;
  NEXT_PUBLIC_NETWORK_SCAN: string | Prepared;
  NEXT_PUBLIC_NETWORK_NAME: string | Prepared;
  NEXT_PUBLIC_BLOCKCHAIN_URL: string | Prepared;
  NEXT_PUBLIC_BLOCKCHAIN_URL_WSS: string | Prepared;
  NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS: string | Prepared;
  NEXT_PUBLIC_SP_GAS_PRICE: number | Prepared;
  NEXT_PUBLIC_LIMIT_ORDERS_RUNNING: number | Prepared;
  NEXT_PUBLIC_DEBUG: boolean | Prepared;
  NEXT_PUBLIC_TESTNET: string | Prepared;
  NEXT_PUBLIC_S3_ENDPOINT: string | Prepared;
  NEXT_PUBLIC_S3_BUCKET: string | Prepared;
  NEXT_PUBLIC_S3_ARGS_BUCKET_READ_ACCESS: string | Prepared;
  NEXT_PUBLIC_S3_REGION: string | Prepared;
  NEXT_PUBLIC_S3_UPLOAD_ACCESS_KEY_ID: string | Prepared;
  NEXT_PUBLIC_S3_UPLOAD_ACCESS_SECRET_KEY: string | Prepared;
  S3_DOWNLOAD_ACCESS_KEY_ID: string | Prepared;
  S3_DOWNLOAD_ACCESS_SECRET_KEY: string | Prepared;
  NEXT_PUBLIC_CHECKCONFIDENTIALITY_URL: string | Prepared;
  NEXT_PUBLIC_PCCS_URL: string | Prepared;
  NEXT_PUBLIC_DISABLE_LOGGER: string | Prepared;
  NEXT_PUBLIC_ANALYTICS_API_URL: string | Prepared;
  NEXT_PUBLIC_ANALYTICS_API_KEY: string | Prepared;
  NEXT_PUBLIC_SHOW_EVENTS: boolean | Prepared;
  NEXT_PUBLIC_OFFERS_SOON: { [key: string]: boolean };
  NEXT_PUBLIC_OFFERS_BASE: { [key: string]: boolean };
  NEXT_PUBLIC_OFFERS_CHECK_INDEX_HTML: { [key: string]: boolean };
  NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_SOLUTION: { [key: string]: boolean };
  NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_DATA: { [key: string]: boolean };
  NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_STORAGE: { [key: string]: boolean };
  NEXT_PUBLIC_APPROVED_OFFERS_SOLUTION: { [key: string]: boolean };
  NEXT_PUBLIC_APPROVED_OFFERS_DATA: { [key: string]: boolean };
  NEXT_PUBLIC_APPROVED_OFFERS_STORAGE: { [key: string]: boolean };
  NEXT_PUBLIC_SECRET_KEEPER_URL: string | Prepared;
  NEXT_PUBLIC_SDK_VERSION: string | Prepared;
}

// null - for checking empty required fields
export const getPreparedConfig = (): Config<null> => ({
  NEXT_PUBLIC_API_ENDPOINT: process.env.NEXT_PUBLIC_API_ENDPOINT || null,
  NEXT_PUBLIC_API_ENDPOINT_WSS: process.env.NEXT_PUBLIC_API_ENDPOINT_WSS || null,
  NEXT_PUBLIC_NETWORK_CHAIN_ID: +(process.env.NEXT_PUBLIC_NETWORK_CHAIN_ID || 0) || 80001,
  NEXT_PUBLIC_NETWORK_SCAN: process.env.NEXT_PUBLIC_NETWORK_SCAN || null,
  NEXT_PUBLIC_NETWORK_NAME: process.env.NEXT_PUBLIC_NETWORK_NAME || null,
  NEXT_PUBLIC_BLOCKCHAIN_URL: process.env.NEXT_PUBLIC_BLOCKCHAIN_URL || null,
  NEXT_PUBLIC_BLOCKCHAIN_URL_WSS: process.env.NEXT_PUBLIC_BLOCKCHAIN_URL_WSS || null,
  NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS || null,
  NEXT_PUBLIC_SP_GAS_PRICE: +(process.env.NEXT_PUBLIC_SP_GAS_PRICE || '') || 60000000000,
  NEXT_PUBLIC_LIMIT_ORDERS_RUNNING: process.env.NEXT_PUBLIC_LIMIT_ORDERS_RUNNING !== undefined
    ? +process.env.NEXT_PUBLIC_LIMIT_ORDERS_RUNNING
    : 2,
  NEXT_PUBLIC_TESTNET: process.env.NEXT_PUBLIC_TESTNET || 'https://docs.superprotocol.com',
  NEXT_PUBLIC_DEBUG: process.env.NEXT_PUBLIC_DEBUG === undefined
    ? true
    : process.env.NEXT_PUBLIC_DEBUG === 'true',
  NEXT_PUBLIC_S3_BUCKET: process.env.NEXT_PUBLIC_S3_BUCKET || 'inputs',
  S3_DOWNLOAD_ACCESS_KEY_ID: process.env.S3_DOWNLOAD_ACCESS_KEY_ID || null,
  S3_DOWNLOAD_ACCESS_SECRET_KEY: process.env.S3_DOWNLOAD_ACCESS_SECRET_KEY || null,
  NEXT_PUBLIC_S3_UPLOAD_ACCESS_KEY_ID: process.env.NEXT_PUBLIC_S3_UPLOAD_ACCESS_KEY_ID || null,
  NEXT_PUBLIC_S3_UPLOAD_ACCESS_SECRET_KEY: process.env.NEXT_PUBLIC_S3_UPLOAD_ACCESS_SECRET_KEY || null,
  NEXT_PUBLIC_S3_ENDPOINT: process.env.NEXT_PUBLIC_S3_ENDPOINT || null,
  NEXT_PUBLIC_S3_ARGS_BUCKET_READ_ACCESS: process.env.NEXT_PUBLIC_S3_ARGS_BUCKET_READ_ACCESS || null,
  NEXT_PUBLIC_S3_REGION: process.env.NEXT_PUBLIC_S3_REGION || 'us-east-1',
  NEXT_PUBLIC_CHECKCONFIDENTIALITY_URL: process.env.NEXT_PUBLIC_CHECKCONFIDENTIALITY_URL || null,
  NEXT_PUBLIC_PCCS_URL: process.env.NEXT_PUBLIC_PCCS_URL || null,
  NEXT_PUBLIC_DISABLE_LOGGER: process.env.NEXT_PUBLIC_DISABLE_LOGGER || '',
  NEXT_PUBLIC_ANALYTICS_API_URL: process.env.NEXT_PUBLIC_ANALYTICS_API_URL || '',
  NEXT_PUBLIC_ANALYTICS_API_KEY: process.env.NEXT_PUBLIC_ANALYTICS_API_KEY || '',
  NEXT_PUBLIC_SHOW_EVENTS: process.env.NEXT_PUBLIC_SHOW_EVENTS === undefined
    ? false
    : process.env.NEXT_PUBLIC_SHOW_EVENTS === 'true',
  NEXT_PUBLIC_OFFERS_SOON: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_OFFERS_SOON),
  NEXT_PUBLIC_OFFERS_BASE: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_OFFERS_BASE),
  NEXT_PUBLIC_OFFERS_CHECK_INDEX_HTML: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_OFFERS_CHECK_INDEX_HTML),
  NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_SOLUTION: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_SOLUTION),
  NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_DATA: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_DATA),
  NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_STORAGE: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_STORAGE),
  NEXT_PUBLIC_APPROVED_OFFERS_SOLUTION: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_APPROVED_OFFERS_SOLUTION),
  NEXT_PUBLIC_APPROVED_OFFERS_DATA: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_APPROVED_OFFERS_DATA),
  NEXT_PUBLIC_APPROVED_OFFERS_STORAGE: getObjFromStringEnvVariable(process.env.NEXT_PUBLIC_APPROVED_OFFERS_STORAGE),
  NEXT_PUBLIC_SECRET_KEEPER_URL: process.env.NEXT_PUBLIC_SECRET_KEEPER_URL || '', // todo change to null later
  NEXT_PUBLIC_SDK_VERSION: process.env.NEXT_PUBLIC_SDK_VERSION || '',
});

// save in closure for better performance
const currentConfig = getPreparedConfig();

const getConfig = (): Config => currentConfig;

export default getConfig;