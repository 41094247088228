import { useEffect } from 'react';
import { isSSR } from 'common/utils';
import { useAppDispatch } from 'lib/hooks';
import { updateSize as updateSizeDispatch } from 'lib/features/windowSize';

export const useWindowSize = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSSR()) return;
    const updateSize = () => {
      dispatch(updateSizeDispatch({ height: window.innerHeight, width: window.innerWidth }));
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [dispatch]);
};
