import { memo, useCallback } from 'react';
import copy from 'copy-to-clipboard';
import cn from 'classnames';
import { Icon } from 'uikit/Icon';
import { Button } from 'uikit/Buttons/Button';
import { Theme } from 'uikit/common/types';
import { CopyToClipboardProps } from './types';
import classes from './CopyToClipboard.module.scss';

export const CopyToClipboard = memo<CopyToClipboardProps>(({
  text, className, theme = Theme.dark, onCopy: onCopyProp, width = 9, iconName = 'copy',
}) => {
  const onCopy = useCallback(() => {
    if (text) {
      copy(text);
      onCopyProp?.(text);
    }
  }, [text, onCopyProp]);

  return (
    <Button variant={theme === Theme.dark ? 'black' : 'white'} onClick={onCopy} className={cn(classes.wrap, className)}>
      <Icon
        width={width}
        name={iconName}
        className={classes.icon}
      />
    </Button>
  );
});
