import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import type { Web3ReactStateUpdate, Actions } from '@web3-react/types';
import isequalwith from 'lodash.isequalwith';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions } from 'lib/features/helpers';
import getConfig from 'config';
import {
  WalletState, SelectedWalletType, WalletInfo, GetActionProps,
} from './types';
import { getInitialBalance, getInitialWallet } from './helpers';
import { updateBalance, changeWallet } from './thunks';
import { Storage } from '../types';

export const getAction = ({
  walletType, dispatch, onUpdate, onResetState,
}: GetActionProps): Actions => ({
  startActivation: () => () => {
    dispatch(updateSelectedWalletType(walletType));
  },
  update: async (stateUpdate: Web3ReactStateUpdate) => {
    dispatch(updateLoadingWallet(true));
    const { chainId, accounts } = stateUpdate || {};
    dispatch(
      updateWallet(
        {
          walletType,
          data: {
            ...(chainId ? { chainId } : {}),
            ...(accounts ? { accounts } : {}),
          },
        },
      ),
    );
    dispatch(
      updateSelectedWalletType(
        chainId && getConfig().NEXT_PUBLIC_NETWORK_CHAIN_ID !== chainId
          ? null
          : walletType,
      ),
    );
    const Web3 = (await import('web3')).default;
    const address = accounts?.length ? Web3.utils.toChecksumAddress(accounts[0]) : undefined;
    dispatch(updateSelectedAddress(address));
    dispatch(updateIsConnected(!!address));
    dispatch(updateLoadingWallet(false));
    onUpdate?.(address);
  },
  resetState() {
    dispatch(reset());
    onResetState?.();
  },
});

const getInitialState = (): WalletState => ({
  loadingBalance: false,
  loadingWallet: false,
  selectedWalletType: null,
  errorWallet: null,
  isConnected: false,
  balance: getInitialBalance(),
  wallet: getInitialWallet(),
});

const NAME = 'wallet';

export const wallet = createSlice({
  name: NAME,
  initialState: getInitialState(),
  reducers: {
    updateIsConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    updateSelectedWalletType(state, action: PayloadAction<SelectedWalletType>) {
      state.selectedWalletType = action.payload;
    },
    updateLoadingWallet(state, action: PayloadAction<boolean>) {
      state.loadingWallet = action.payload;
    },
    updateSelectedAddress(state, action: PayloadAction<string | undefined>) {
      state.selectedAddress = action.payload;
    },
    updateWallet(state, action: PayloadAction<{ walletType: SelectedWalletType, data: WalletInfo }>) {
      const { walletType, data } = action.payload || {};
      if (walletType) {
        state.wallet = {
          ...state.wallet,
          [walletType]: {
            ...state.wallet[walletType],
            ...data,
          },
        };
      }
    },
    reset() {
      return getInitialState();
    },
  },
  extraReducers: (builder) => {
    // updateBalance
    builder.addCase(updateBalance.pending, (state) => {
      state.loadingBalance = true;
    });
    builder.addCase(updateBalance.fulfilled, (state, action) => {
      if (!isequalwith(state.balance, action.payload)) {
        state.balance = action.payload;
      }
      state.loadingBalance = false;
    });
    builder.addCase(updateBalance.rejected, (state) => {
      state.balance = getInitialBalance();
      state.loadingBalance = false;
    });
    // changeWallet
    builder.addCase(changeWallet.pending, (state) => {
      state.errorWallet = null;
      state.loadingWallet = true;
    });
    builder.addCase(changeWallet.fulfilled, (state) => {
      state.loadingWallet = false;
    });
    builder.addCase(changeWallet.rejected, (state, error) => {
      state.errorWallet = error?.error;
      state.loadingWallet = false;
      state.selectedWalletType = null;
    });
  },
  selectors: {
    selectedWalletTypeSelector: (state) => state.selectedWalletType,
    loadingWalletSelector: (state) => state.loadingWallet,
    loadingBalanceSelector: (state) => state.loadingBalance,
    selectedAddressSelector: (state) => state.selectedAddress,
    isConnectedSelector: (state) => state.isConnected,
    balanceSelector: (state) => state.balance,
    errorWalletSelector: (state) => state.errorWallet,
  },
});

export const {
  updateSelectedWalletType, updateLoadingWallet, updateWallet, updateSelectedAddress, updateIsConnected, reset,
} = wallet.actions;

export const {
  selectedWalletTypeSelector,
  loadingWalletSelector,
  loadingBalanceSelector,
  selectedAddressSelector,
  isConnectedSelector,
  balanceSelector,
  errorWalletSelector,
} = wallet.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'wallet', storage, whitelist: ['selectedWalletType'],
}, wallet.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(wallet);