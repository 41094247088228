import {
  configureStore, ThunkAction, Action, combineReducers,
} from '@reduxjs/toolkit';
import type { NextApiRequest, NextApiResponse } from 'next';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { isSSR } from 'common/utils';
import { api as apiCommon } from '../generated/schemas/common';
import { orders } from './features/orders';
import { offers } from './features/offers';
import { teeOffers } from './features/teeOffers';
import { providers } from './features/providers';
import { eventsLog } from './features/eventsLog';
import { orderDetails } from './features/orderDetails';
import { sdk, getBroatcastWhiteList as getBroatcastWhiteListSdk } from './features/sdk';
import { notifications, getBroatcastWhiteList as getBroatcastWhiteListNotifications } from './features/notifications';
import { getReducer as getReducerAlerts, getBroatcastWhiteList as getBroatcastWhiteListAlerts, alerts } from './features/alerts';
import { getReducer as getReducerWallet, getBroatcastWhiteList as getBroatcastWhiteListWallet, wallet } from './features/wallet';
import { getReducer as getReducerTableSettings, tableSettings } from './features/tableSettings';
import {
  getReducer as getReducerCreateOrder,
  getBroatcastWhiteList as getBroatcastWhiteListCreateOrder, createOrder,
} from './features/createOrder';
import {
  getReducer as getReducerControlPanel,
  getBroatcastWhiteList as getBroatcastWhiteListControlPanel, controlPanel,
} from './features/controlPanel';
import {
  getReducer as getReducerOrdersSortingMethods,
  getBroatcastWhiteList as getBroatcastWhiteListordersSortingMethods, ordersSortingMethods,
} from './features/ordersSortingMethods';
import { getReducer as getReducerTheme, getBroatcastWhiteList as getBroatcastWhiteListTheme, theme } from './features/theme';
import { getReducer as getReducerSystem, getBroatcastWhiteList as getBroatcastWhiteListSystem, system } from './features/system';
import {
  getReducer as getReducerSecretKeeper,
  getBroatcastWhiteList as getBroatcastWhiteListSecretKeeper,
  secretKeeper,
} from './features/secretKeeper';
import {
  getReducer as getReducerFilters,
  getBroatcastWhiteList as getBroatcastWhiteListFilters,
  filters,
} from './features/filters';
import { events } from './features/events';
import { windowSize } from './features/windowSize';
import {
  createCookieStorage, createLocalStorage, createNoopStorage, createSessionStorage,
} from './features/helpers';
import { Storages, Storage } from './features/types';

// use broadcast api only for required reducers
const getStateSyncMiddleware = () => createStateSyncMiddleware({
  prepareState: (state) => state.toJS(),
  receiveState: (_, nextState) => nextState,
  whitelist: [
    ...getBroatcastWhiteListCreateOrder(),
    ...getBroatcastWhiteListTheme(),
    ...getBroatcastWhiteListControlPanel(),
    ...getBroatcastWhiteListFilters(),
    ...getBroatcastWhiteListWallet(),
    ...getBroatcastWhiteListAlerts(),
    ...getBroatcastWhiteListNotifications(),
    ...getBroatcastWhiteListSdk(),
    ...getBroatcastWhiteListordersSortingMethods(),
    ...getBroatcastWhiteListSystem(),
    ...getBroatcastWhiteListSecretKeeper(),
  ],
});

export const storages = {
  createOrder: Storages.cookie,
  wallet: Storages.localStorage,
  tableSettings: Storages.localStorage,
  controlPanel: Storages.cookie,
  theme: Storages.cookie,
  filters: Storages.cookie,
  alerts: Storages.localStorage,
  ordersSortingMethods: Storages.localStorage,
  system: Storages.localStorage,
  secretKeeper: Storages.sessionStorage,
};

export const getStorage = (
  reducerName: keyof typeof storages | undefined,
  props: { req?: NextApiRequest, res?: NextApiResponse } | undefined,
): Storage => {
  const storage = storages[reducerName as keyof typeof storages];
  switch (storage) {
    case Storages.localStorage:
      return createLocalStorage();
    case Storages.cookie:
      return createCookieStorage(props);
    case Storages.sessionStorage:
      return createSessionStorage();
    default:
      return createNoopStorage();
  }
};

const getRootReducer = (props: { req?: NextApiRequest, res?: NextApiResponse } | undefined) => combineReducers({
  [offers.reducerPath]: offers.reducer,
  [teeOffers.reducerPath]: teeOffers.reducer,
  [providers.reducerPath]: providers.reducer,
  [orders.reducerPath]: orders.reducer,
  [apiCommon.reducerPath]: apiCommon.reducer,
  [eventsLog.reducerPath]: eventsLog.reducer,
  orderDetails: orderDetails.reducer,
  sdk: sdk.reducer,
  notifications: notifications.reducer,
  windowSize: windowSize.reducer,
  createOrder: getReducerCreateOrder(getStorage(createOrder.name, props)),
  wallet: getReducerWallet(getStorage(wallet.name, props)),
  tableSettings: getReducerTableSettings(getStorage(tableSettings.name, props)),
  controlPanel: getReducerControlPanel(getStorage(controlPanel.name, props)),
  theme: getReducerTheme(getStorage(theme.name, props)),
  events: events.reducer,
  filters: getReducerFilters(getStorage(filters.name, props)),
  alerts: getReducerAlerts(getStorage(alerts.name, props)),
  ordersSortingMethods: getReducerOrdersSortingMethods(getStorage(ordersSortingMethods.name, props)),
  system: getReducerSystem(getStorage(system.name, props)),
  secretKeeper: getReducerSecretKeeper(getStorage(secretKeeper.name, props)),
});

export const makeStore = (
  props?: CreateStoreProps,
) => {
  const {
    req, res, cb, preloadedState,
  } = props || {};
  const store = configureStore({
    reducer: getRootReducer({ req, res }),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'createOrder/updateProcess', // ignore serialize for Error instance
        ],
        ignoredPaths: ['createOrder.process'], // ignore serialize for Error instance
      },
    })
      .concat(!isSSR() ? [getStateSyncMiddleware()] : [])
      .prepend(
        teeOffers.middleware,
        offers.middleware,
        providers.middleware,
        orders.middleware,
        eventsLog.middleware,
        apiCommon.middleware as any, // todo add types
      ),
  });
  const persistor = persistStore(store, {}, () => {
    cb?.(store);
  });
  (store as any).__persistor = persistor;
  if (!isSSR()) {
    initMessageListener(store);
  }

  return store;
};

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface CreateStoreProps {
  req?: NextApiRequest & { __reduxStoreServer?: AppStore };
  res?: NextApiResponse;
  cb?: (store: any) => void; // AppStore
  preloadedState?: any; // AppStore
 }

// wait for rehydration to finish
export const createStoreServer = async (props: CreateStoreProps): Promise<AppStore> => new Promise((resolve) => {
  const { req, res } = props || {};
  if (!req?.__reduxStoreServer) {
    const store = makeStore({
      req,
      res,
      cb: resolve,
    });
    if (req) {
      // save previous server store
      req.__reduxStoreServer = store;
    }
    // does not need persist on the server
    (store as any).__persistor.pause();
    return;
  }
  resolve(req?.__reduxStoreServer);
});

let reduxStoreClient: AppStore;

export const createStoreClient = (props: CreateStoreProps) => {
  if (!reduxStoreClient || isSSR()) {
    // save previous client
    reduxStoreClient = makeStore(props);
    return reduxStoreClient;
  }
  return reduxStoreClient;
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
