import { UnknownAction, Slice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import type { NextApiRequest, NextApiResponse } from 'next';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { isSSR } from 'common/utils';
import { Storage } from './types';

export const HYDRATE_STORE = 'HYDRATE_STORE';

export const isError = (action: UnknownAction): boolean => {
  return action.type.endsWith('rejected');
};

export const isHydrateStore = (action: UnknownAction): boolean => {
  return action.type === HYDRATE_STORE;
};

export function hydrate<T>(builder: ActionReducerMapBuilder<T>, name: string) {
  builder.addMatcher(isHydrateStore, (_, action: UnknownAction) => {
    return action.payload?.[name];
  });
}

export const createNoopStorage = (): Storage => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: any) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

export const createCookieStorage = (props: { req?: NextApiRequest, res?: NextApiResponse } | undefined): Storage => {
  const { req, res } = props || {};
  const cookieProps = req && res ? { req, res } : undefined;
  return {
    getItem(key: string) {
      return Promise.resolve(isSSR() ? getCookie(key, cookieProps) : getCookie(key));
    },
    setItem(key: string, value: any) {
      return Promise.resolve(isSSR() ? setCookie(key, value, cookieProps) : setCookie(key, value));
    },
    removeItem(key: string) {
      return Promise.resolve(isSSR() ? deleteCookie(key, cookieProps) : deleteCookie(key));
    },
  };
};

export const createSessionStorage = () => (!isSSR() ? createWebStorage('session') : createNoopStorage());

export const createLocalStorage = () => (!isSSR() ? createWebStorage('local') : createNoopStorage());

export const getBroatcastActions = (slice: Slice): string[] => {
  if (!slice?.name || !slice?.actions) return [];
  return Object.keys(slice.actions).map((action) => `${slice.name}/${action}`);
};