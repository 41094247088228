import * as Types from '../types';

import { api } from '../../connectors/sp';
export type CheckAuthTokenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckAuthTokenQuery = { __typename?: 'Query', checkAuthToken: string };


export const CheckAuthTokenDocument = `
    query CheckAuthToken {
  checkAuthToken
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CheckAuthToken: build.query<CheckAuthTokenQuery, CheckAuthTokenQueryVariables | void>({
      query: (variables) => ({ document: CheckAuthTokenDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckAuthTokenQuery, useLazyCheckAuthTokenQuery } = injectedRtkApi;

