import { useCallback } from 'react';
import { useAppSelector } from 'lib/hooks';
import { selectedWalletTypeSelector } from 'lib/features/wallet';
import { SelectedWalletType } from 'lib/features/wallet/types';
import { WalletType } from 'common/types/wallet';
import { providers, EIP6963ProviderDetail, EIP1193Provider } from './useSyncProviders';

const getProviderFromWindow = (walletType: WalletType | null): EIP1193Provider | null => {
  return (window.ethereum as any)?.providerMap?.get(walletType) || window.ethereum || null;
};

const getProviderByEIP6963 = (
  walletType: WalletType | null,
  providers: Map<string, EIP6963ProviderDetail>,
): EIP1193Provider | null => {
  return [...providers.entries()].find(([, detail]) => detail?.info?.name === walletType)?.[1]?.provider || null;
};

export const getWeb3InstanceByWalletType = async (selectedWalletType: SelectedWalletType) => {
  const provider = getProviderByEIP6963(selectedWalletType, providers) || getProviderFromWindow(selectedWalletType);
  if (!provider) return null;
  const Web3 = (await import('web3')).default;
  return new Web3(provider);
};

const useWeb3Provider = () => {
  const selectedWalletType = useAppSelector(selectedWalletTypeSelector);

  const getWeb3Instance = useCallback(async () => {
    return getWeb3InstanceByWalletType(selectedWalletType);
  }, [selectedWalletType]);

  return { getWeb3Instance };
};

export default useWeb3Provider;