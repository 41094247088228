/* eslint-disable prefer-destructuring */
import { OrderStatus } from '@super-protocol/sdk-js';
import {
  Encryption,
  StorageProviderResource,
} from '@super-protocol/dto-js';
import { getLibarchivejs } from 'common/utils/libarchivejs';
import { getBase64FromBlob, getFileFromArrayBuffer, getTextFromFile } from 'common/utils';
import { MAX_LENGTH_BASE64_MNEMONIC, generateKeysByMnemonic } from 'common/utils/crypto';
import { getDownloadFileS3 } from 'connectors/server';
import { ErrorDecription, EncryptedResult } from './types';

export type Response = { error: string; }

export const getFileContentFromBlob = async (blob: Blob, encryptionProp: Encryption): Promise<string> => {
  const base64WithoutTags = (await getBase64FromBlob(blob) as string).split(',').pop();
  if (!base64WithoutTags) throw new Error('File is empty');
  const encryption = {
    ...encryptionProp,
    ciphertext: base64WithoutTags,
  };
  const { Crypto } = await import('@super-protocol/sdk-js');
  return Crypto.decrypt(encryption);
};

export const getFileName = (filepath: string, orderId: string) => {
  const splitedFileName = filepath.replace('.encrypted', '').split('.');
  splitedFileName[0] = `${splitedFileName[0]}_${orderId}`;
  return splitedFileName.join('.');
};

export const getArchiveResultText = async (arrayBuffer: ArrayBuffer, fileName: string): Promise<string> => {
  const libarchivejs = await getLibarchivejs();
  const file = getFileFromArrayBuffer(arrayBuffer, fileName);
  const openedArchive = await libarchivejs.open(file);
  const files = await openedArchive.extractFiles();
  const resulJson = files?.output?.['result.json'];
  if (!resulJson) return '';
  return getTextFromFile(resulJson);
};

export const downloadFile = (base64: string, fileName: string) => {
  const pom = document.createElement('a');
  pom.setAttribute('href', `data:application/octet-stream;charset=utf-16le;base64, ${base64}`);
  pom.setAttribute('download', fileName);

  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
};

const tryDecryptWithKeys = async (
  encryption: Encryption,
  decryptionKeys: string[],
): Promise<string> => {
  if (!encryption) {
    throw new Error('Encrypted data required');
  }

  if (!decryptionKeys?.length) {
    throw new Error('Decryption keys required');
  }

  const { Crypto } = await import('@super-protocol/sdk-js');
  let error: Error | undefined;

  for (let i = 0; i < decryptionKeys.length; i++) {
    const decryptionKey = decryptionKeys[i];
    try {
      encryption.key = decryptionKey;
      return await Crypto.decrypt(encryption);
    } catch (e) {
      error = e as Error;
    }
  }

  if (error) {
    throw error;
  }

  return '';
};

export const encodingAndDownloadFile = async (
  orderId: string,
  phrase: string,
  status?: OrderStatus,
  encryptedResult?: string,
): Promise<{ isFile: boolean, content: string }> => {
  let privateKeyBase64 = phrase;

  if (phrase.length > MAX_LENGTH_BASE64_MNEMONIC) {
    privateKeyBase64 = (await generateKeysByMnemonic(phrase))?.privateKeyBase64;
  }

  if (!encryptedResult) {
    throw new Error('Order encrypted result required');
  }

  if (!privateKeyBase64) {
    throw new Error('Private key required');
  }

  const COMMON_ERROR_PHRASE = 'Unable to decrypt order result with this passphrase';
  let decrypted = '';
  const encryptedObj: EncryptedResult | Encryption = JSON.parse(encryptedResult);

  if (!encryptedObj) {
    throw new Error(COMMON_ERROR_PHRASE);
  }

  const { RIGenerator, Crypto } = await import('@super-protocol/sdk-js');

  const derivedPrivateKey = (await RIGenerator.getDerivedPrivateKey({
    ...(encryptedObj as EncryptedResult)?.encryption || encryptedObj,
    key: Crypto.getPublicKey({ ...(encryptedObj as EncryptedResult)?.encryption || encryptedObj, key: privateKeyBase64 }).key,
  }));

  if ((encryptedObj as EncryptedResult)?.resource && (encryptedObj as EncryptedResult)?.encryption) {
    const decryptedResource = await tryDecryptWithKeys(
      (encryptedObj as EncryptedResult).resource,
      [privateKeyBase64, derivedPrivateKey.key],
    );

    const decryptedEncryption = await tryDecryptWithKeys(
      (encryptedObj as EncryptedResult).encryption,
      [privateKeyBase64, derivedPrivateKey.key],
    );

    if (!decryptedResource || !decryptedEncryption) {
      throw new Error(COMMON_ERROR_PHRASE);
    }

    decrypted = `{ "resource": ${decryptedResource}, "encryption": ${decryptedEncryption} }`;
  } else {
    decrypted = await tryDecryptWithKeys(encryptedObj as unknown as Encryption, [privateKeyBase64, derivedPrivateKey.key]) || '';

    if (!decrypted) {
      throw new Error(COMMON_ERROR_PHRASE);
    }

    const decryptedResult: ErrorDecription = JSON.parse(decrypted);

    if (decryptedResult?.name?.indexOf?.('Error') !== -1) {
      if (status === OrderStatus.Error) {
        throw new Error(decryptedResult?.message || COMMON_ERROR_PHRASE);
      } else if (decryptedResult?.message) {
        decrypted = decryptedResult.message;
      } else {
        return {
          isFile: false,
          content: JSON.stringify(decryptedResult, null, 2),
        };
      }
    }
  }

  if (!decrypted) {
    throw new Error(COMMON_ERROR_PHRASE);
  }

  const decryptedObj: { resource: StorageProviderResource, encryption: Encryption } = JSON.parse(decrypted);

  const { resource, encryption } = decryptedObj || {};
  const { filepath } = resource || {};

  if (!filepath) {
    return {
      isFile: false,
      content: JSON.stringify(decryptedObj, null, 2),
    };
  }

  const { prefix, bucket } = decryptedObj.resource.credentials;
  const response = await getDownloadFileS3({ fileName: `${prefix}${filepath}`, bucket });

  if (!response?.ok) {
    const json: Response | null = await response.json().catch(() => null);
    throw new Error(json?.error ?? 'Unable to get file result');
  }

  const fileContent = await getFileContentFromBlob(await response.blob(), encryption);
  const fileName = getFileName(filepath, orderId);
  const arrayBuffer = Buffer.from(fileContent, 'binary');
  const text = await getArchiveResultText(arrayBuffer, fileName);
  const base64 = arrayBuffer.toString('base64');
  downloadFile(base64, fileName);

  return {
    isFile: true,
    content: text,
  };
};
