import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { getBroatcastActions, isError } from 'lib/features/helpers';
import { parseJwt } from 'common/utils';
import { connect } from './thunks';
import { Storage } from '../types';
import { SecretKeeperState, JWT } from './types';
import { getInitialState } from './helpers';

const initialState: SecretKeeperState = getInitialState();

export const secretKeeper = createSlice({
  name: 'secretKeeper',
  initialState,
  reducers: {
    updateAccessToken(state, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
    reset() {
      return getInitialState();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connect.pending, (state) => {
        state.loading = true;
        state.operation = 'connect';
      })
      .addCase(connect.fulfilled, (state, action: PayloadAction<string | null>) => {
        state.loading = false;
        state.accessToken = action.payload;
      })
      .addMatcher(isError, (state) => {
        state.loading = false;
        state.accessToken = null;
      });
  },
  selectors: {
    accessTokenSelector: (state) => state.accessToken,
    accountSelector: (state) => parseJwt<JWT>(state.accessToken)?.payload?.account || null,
  },
});

export const {
  updateAccessToken,
  reset,
} = secretKeeper.actions;

export const {
  accessTokenSelector,
  accountSelector,
} = secretKeeper.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'secretKeeper', storage, whitelist: ['accessToken'],
}, secretKeeper.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(secretKeeper);
