import {
  memo, ReactElement, useCallback, useMemo, useState,
} from 'react';
import { FilesProviderProps, Files } from './types';
import { FilesContextProvider } from './FilesContext';

export const FilesProvider = memo<FilesProviderProps>(({ children }): ReactElement => {
  const [files, setFiles] = useState<Files>({});
  const onChangeFiles = useCallback((id: string, file: File) => {
    setFiles((files) => ({ ...files, [id]: file }));
  }, []);
  const onDeleteFile = useCallback((id: string) => {
    setFiles((files) => {
      return Object.entries(files).reduce((acc, [fileId, file]) => (fileId === id ? acc : { ...acc, [fileId]: file }), {});
    });
  }, []);
  const reset = useCallback(() => {
    setFiles({});
  }, []);
  const value = useMemo(() => ({
    files, onChangeFiles, onDeleteFile, reset,
  }), [files, onChangeFiles, onDeleteFile, reset]);
  return (
    <FilesContextProvider
      value={value}
    >
      {children}
    </FilesContextProvider>
  );
});
