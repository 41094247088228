import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAddressFromAuthorizationToken, getAuthorizationToken } from 'common/utils';
import getConfig from 'config';
import { SystemState } from '../system/types';
import { EventProperty } from './types';

export const trackEvent = createAsyncThunk<
void,
  { eventType: string, property?: string | EventProperty },
  { rejectValue: string | Error, state: { system: SystemState } }
>(
  'events/trackEvent',
  async ({ eventType, property }, { getState }) => {
    const state = getState();
    const config = getConfig();
    const token = getAuthorizationToken();
    const addressFromToken = getAddressFromAuthorizationToken(token);
    if (!addressFromToken) return;
    const { deviceId } = state.system || {};
    const { Analytics, BrowserEventProvider } = await import('@super-protocol/sdk-js');
    const browserEventProvider = new BrowserEventProvider({
      userId: addressFromToken || '',
      deviceId,
    });
    const analytics = new Analytics({
      apiUrl: config.NEXT_PUBLIC_ANALYTICS_API_URL,
      apiKey: config.NEXT_PUBLIC_ANALYTICS_API_KEY,
      eventProvider: browserEventProvider,
    });
    await analytics.trackEventCatched({
      eventName: eventType,
      eventProperties: property,
    });
  },
);
