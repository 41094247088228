import Web3 from 'web3';
import {
  OrderStatus, OrderInfo, OrderSlots, OfferType, ParamName,
} from '@super-protocol/sdk-js';
import {
  Encoding,
  Encryption,
  CryptoAlgorithm,
  Hash,
} from '@super-protocol/dto-js';
import { BigNumber } from 'bignumber.js';
import { generateKeysByMnemonic } from 'common/utils/crypto';
import getConfig from 'config';
import { getExternalId } from 'common/utils';
import { Slots } from 'lib/features/createOrder/types';

export interface WorkflowPropsValuesOffer {
  value?: string;
  slots?: Slots | null;
}

export interface ApproveWorkflowProps {
  actionAccountAddress: string;
  web3: Web3;
}

export interface WorkflowPropsValues {
  solution?: WorkflowPropsValuesOffer[];
  data?: WorkflowPropsValuesOffer[];
  tee?: WorkflowPropsValuesOffer;
  storage?: WorkflowPropsValuesOffer;
  deposit: string; // wei
  mnemonic: string;
  args?: string;
  teeExternalId: string;
  dataHashes?: Hash[];
}

export interface WorkflowProps {
  values: WorkflowPropsValues;
  actionAccountAddress: string;
  web3: Web3;
}

export interface GetWorkflowTEEOrderProps {
  offer: string;
  args?: string;
  inputOffers?: string[];
  outputOffer?: string;
  algo: CryptoAlgorithm;
  privateKeyBase64: string;
  externalId: string;
  dataResourceHashes?: Hash[];
}

export interface GetWorkflowSubOrderProps {
  offer: string;
  externalId: string;
  inputOffers?: string[];
  outputOffer?: string;
}

export interface ReplenishOrderProps {
  orderId?: string;
  amount?: number;
  instance?: Web3;
  accountAddress?: string;
}

export interface CancelOrderProps {
  orderId?: string;
  instance?: Web3;
  accountAddress?: string;
}

export interface ApproveValuesProps {
  deposit: string; // wei
}
export interface ApproveProps {
  values: ApproveValuesProps;
  actionAccountAddress: string;
  web3: Web3;
}

export default class BlockchainConnector {
  private static instance: BlockchainConnector;
  private initialized = false;

  private constructor() {}

  public static getInstance(): BlockchainConnector {
    if (!BlockchainConnector.instance) {
      BlockchainConnector.instance = new BlockchainConnector();
    }

    return BlockchainConnector.instance;
  }

  public isInitialized() {
    return this.initialized;
  }

  public checkIfInitialized(): void {
    if (!this.initialized) {
      throw new Error(
        `${this.constructor.name} is not initialized, needs to run '${this.constructor.name}.initialize(CONFIG)' first`,
      );
    }
  }

  private async initialize(): Promise<BlockchainConnector> {
    if (this.isInitialized()) return BlockchainConnector.getInstance();
    const {
      NEXT_PUBLIC_BLOCKCHAIN_URL,
      NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS,
    } = getConfig();
    if (!NEXT_PUBLIC_BLOCKCHAIN_URL) {
      throw new Error('BlockchainUrl is node defined');
    }
    if (!NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS) {
      throw new Error('ContractAddress is undefined');
    }
    const { BlockchainConnector: BlockchainConnectorSDK } = (await import('@super-protocol/sdk-js'));
    const instance = BlockchainConnectorSDK.getInstance();
    await instance.initialize({
      blockchainUrl: NEXT_PUBLIC_BLOCKCHAIN_URL,
      contractAddress: NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS,
    });
    this.initialized = true;
    return BlockchainConnector.getInstance();
  }

  public async teeBalanceOf(address: string): Promise<string> {
    await this.initialize();
    const { SuperproToken } = (await import('@super-protocol/sdk-js'));
    return SuperproToken.balanceOf(address);
  }

  public async balanceOf(address: string): Promise<string> {
    await this.initialize();
    const { BlockchainConnector: BlockchainConnectorSDK } = (await import('@super-protocol/sdk-js'));
    return BlockchainConnectorSDK.getInstance().getBalance(address);
  }

  public static prepareSlots(slots?: Slots | null): OrderSlots {
    const { slot, options = [] } = slots || {};
    const { optionsIds = [], optionsCount = [] } = (options || []).reduce((acc, { id, count }) => {
      return {
        ...acc,
        optionsIds: [...acc.optionsIds, id],
        optionsCount: [...acc.optionsCount, count],
      };
    }, {
      optionsIds: [] as string[],
      optionsCount: [] as number[],
    }) || {};
    return {
      slotCount: slot?.count ?? 0,
      slotId: slot?.id ?? '',
      optionsIds,
      optionsCount,
    };
  }

  public async getWorkflowSubOrder(props: GetWorkflowSubOrderProps): Promise<OrderInfo> {
    await this.initialize();

    const {
      offer,
      outputOffer = '',
      inputOffers = [],
      externalId,
    } = props || {};

    if (!offer) {
      throw new Error('Offer id required');
    }

    if (!externalId) {
      throw new Error('External id required');
    }

    return {
      offerId: offer,
      encryptedArgs: '',
      resultInfo: {
        publicKey: '',
        encryptedInfo: '',
      },
      status: OrderStatus.New,
      args: {
        inputOffers,
        outputOffer,
      },
      externalId,
    };
  }

  public async getWorkflowTEEOrder(props: GetWorkflowTEEOrderProps): Promise<OrderInfo> {
    await this.initialize();

    const {
      offer,
      args = '',
      algo,
      inputOffers = [],
      outputOffer = '',
      externalId,
      privateKeyBase64,
      dataResourceHashes,
    } = props || {};

    if (!algo) {
      throw Error('Encryption algo required');
    }

    if (algo !== CryptoAlgorithm.ECIES) {
      throw Error('Only ECIES result encryption is supported');
    }

    if (!offer) {
      throw new Error('Offer id required');
    }

    if (!privateKeyBase64) {
      throw new Error('Private key required');
    }

    if (!externalId) {
      throw new Error('External id required');
    }

    const {
      TeeOffer, RIGenerator, TIIGenerator, Crypto,
    } = await import('@super-protocol/sdk-js');

    const offerInfo = await new TeeOffer(offer).getInfo();

    const { NEXT_PUBLIC_PCCS_URL } = getConfig();

    const {
      solutionHashes, dataHashes, linkage, imageHashes,
    } = await TIIGenerator.getOffersHashesAndLinkage(inputOffers);

    const orderResultKeys = await RIGenerator.generate({
      offerId: offer,
      encryptionPrivateKey: {
        algo,
        encoding: Encoding.base64,
        key: privateKeyBase64,
      },
      pccsServiceApiUrl: NEXT_PUBLIC_PCCS_URL,
      solutionHashes,
      dataHashes: dataResourceHashes?.length ? [...dataHashes, ...dataResourceHashes] : dataHashes,
      linkage,
      imageHashes,
    });

    const parsedArgsPublicKey: Encryption | null = offerInfo?.argsPublicKey ? JSON.parse(offerInfo.argsPublicKey) : null;

    const encryptedArgs = args && parsedArgsPublicKey
      ? JSON.stringify(await Crypto.encrypt(args, parsedArgsPublicKey))
      : '';

    return {
      offerId: offer,
      encryptedArgs,
      resultInfo: orderResultKeys,
      status: OrderStatus.New,
      args: {
        inputOffers,
        outputOffer,
      },
      externalId,
    };
  }

  public async workflow(props: WorkflowProps): Promise<string | void> {
    await this.initialize();

    const {
      values,
      actionAccountAddress,
      web3,
    } = props;

    const {
      tee,
      storage,
      data,
      solution,
      deposit,
      mnemonic,
      args,
      teeExternalId,
      dataHashes,
    } = values || {};

    const { value: teeOffer, slots } = tee || {};

    if (!mnemonic) throw new Error('Passphrase required');
    if (!teeOffer) throw new Error('Tee offer required');
    if (!slots?.slot?.id) throw new Error('Slots required');

    const { Orders } = await import('@super-protocol/sdk-js');

    const { privateKeyBase64 } = await generateKeysByMnemonic(mnemonic.trim());

    const parentOrderInfo = await this.getWorkflowTEEOrder({
      args,
      privateKeyBase64,
      offer: teeOffer as string,
      algo: CryptoAlgorithm.ECIES,
      inputOffers: (data || []).concat(solution || []).map(({ value }) => value as string),
      outputOffer: storage?.value,
      externalId: teeExternalId,
      dataResourceHashes: dataHashes,
    });

    const subOrders = (solution || []).concat(data || []);

    const subOrdersInfo = await Promise.all(
      subOrders
        .map(async ({ value }) => {
          return this.getWorkflowSubOrder({
            offer: value as string,
            outputOffer: storage?.value,
            externalId: getExternalId(),
          });
        }),
    );

    return Orders.createWorkflow(
      parentOrderInfo,
      BlockchainConnector.prepareSlots(slots),
      subOrdersInfo,
      subOrders.map(({ slots }) => BlockchainConnector.prepareSlots(slots)),
      deposit,
      { from: actionAccountAddress, web3: web3 as any }, // todo update web3 package
      true,
    );
  }

  public async approve(props: ApproveProps): Promise<void> {
    await this.initialize();
    const { values, actionAccountAddress, web3 } = props || {};
    const { deposit } = values || {};
    const { Orders, SuperproToken } = await import('@super-protocol/sdk-js');
    const Web3 = (await import('web3')).default;
    const value = await SuperproToken.allowance(actionAccountAddress, Orders.address);
    if (new BigNumber(value.toString()).isLessThan(new BigNumber(deposit))) {
      await SuperproToken.approve(
        Orders.address,
        Web3.utils.toWei(new BigNumber(1e10).toString(), 'ether'),
        { from: actionAccountAddress, web3: web3 as any }, // todo update web3 package
      );
    }
  }

  public async replenishOrder({
    orderId,
    amount,
    instance,
    accountAddress,
  }: ReplenishOrderProps): Promise<void> {
    await this.initialize();
    if (!orderId) throw new Error('Order id required');
    if (!accountAddress) throw new Error('Account address required');
    if (!instance) throw new Error('Web3 instance required');
    if (!amount) throw new Error('Amount required');

    const Web3 = (await import('web3')).default;
    const { Orders } = await import('@super-protocol/sdk-js');

    const amountInWei = Web3.utils.toWei(amount.toString(), 'ether');

    await this.approve({
      actionAccountAddress: accountAddress,
      values: { deposit: amountInWei },
      web3: instance,
    });
    await Orders.refillOrderDeposit(
      orderId,
      amountInWei,
      { from: accountAddress, web3: instance as any }, // todo update web3 package
    );
  }

  public async cancelOrder({
    orderId,
    instance,
    accountAddress,
  }: CancelOrderProps): Promise<void> {
    if (!orderId) throw new Error('Order id required');
    if (!accountAddress) throw new Error('Account address required');
    if (!instance) throw new Error('Web3 instance required');
    await this.initialize();

    const { Orders } = await import('@super-protocol/sdk-js');
    await Orders.cancelWorkflow(
      orderId,
      { from: accountAddress, web3: instance as any },
    );
  }

  public async getAllowance(actionAccountAddress: string): Promise<string> {
    await this.initialize();
    const { Orders, SuperproToken } = await import('@super-protocol/sdk-js');
    return (
      await SuperproToken.allowance(actionAccountAddress, Orders.address)
    ).toString();
  }

  public async getOfferHoldSum(offerId: string, slots?: Slots): Promise<string> {
    await this.initialize();
    if (!offerId || !slots?.slot?.id) return '0';
    const { TeeOffer, Offer } = await import('@super-protocol/sdk-js');
    const offerInstance = new TeeOffer(offerId);
    const offerType = await offerInstance.getOfferType();
    const result = offerType === OfferType.TeeOffer
      ? await new TeeOffer(offerId).getMinDeposit(slots.slot.id, slots.slot.count, [], [])
      : await new Offer(offerId).getMinDeposit(slots.slot.id);
    return result.toString();
  }

  public async getOrderMinDeposit(): Promise<string> {
    await this.initialize();
    const { Superpro } = (await import('@super-protocol/sdk-js'));
    return (await Superpro.getParam(ParamName.OrderMinimumDeposit)).toString();
  }

  public async approveWorkflow(props: ApproveWorkflowProps): Promise<void> {
    await this.initialize();
    const { actionAccountAddress, web3 } = props || {};
    const { Orders, SuperproToken } = await import('@super-protocol/sdk-js');
    const Web3 = (await import('web3')).default;
    await SuperproToken.approve(
      Orders.address,
      Web3.utils.toWei(new BigNumber(1e10).toString(), 'ether'),
      { from: actionAccountAddress, web3: web3 as any }, // todo update web3 package
    );
  }

  public async shutdown(): Promise<void> {
    if (this.initialized) {
      this.initialized = false;
      const { BlockchainConnector: BlockchainConnectorSDK } = (await import('@super-protocol/sdk-js'));
      BlockchainConnectorSDK.getInstance().shutdown();
    }
  }
}
