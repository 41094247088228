export interface Storage {
  getItem(key: string, ...args: Array<any>): any;
  setItem(key: string, value: any, ...args: Array<any>): any;
  removeItem(key: string, ...args: Array<any>): any;
}

export enum Storages {
  localStorage = 'localStorage',
  cookie = 'cookie',
  sessionStorage = 'sessionStorage',
}