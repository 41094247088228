import { Component } from 'react';
import { ErrorComponent } from 'components/Errors/Error';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if ((this.state as any)?.hasError) {
      return <ErrorComponent />;
    }
    return (this.props as any).children;
  }
}