import { ColumnDef } from '@tanstack/react-table';
import { OffersPages } from 'common/types/pages';
import { Column } from '../Table/types';

export interface ControlPanelProps {
  name: string;
  onSubmit: () => void;
  columns: ColumnDef<Column>[];
  active: OffersPages;
  showCount?: number;
  totalCount?: number;
  interval?: number;
}

export enum ControlPanelFields {
  sorting = 'sorting',
  size = 'size',
}