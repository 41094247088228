import pino, { Logger as LoggerType } from 'pino';

const logger = pino(({
  browser: {
    asObject: true,
    serialize: true,
  },
}));

export type Logger = LoggerType;

export default logger;