import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUser, postUser, auth, getDataFromStorage, StoragePayloadData,
} from 'connectors/secretkeeper';
import { SecretKeeperState } from './types';
import { createSignature } from './helpers';
import { WalletState } from '../wallet/types';

export const connect = createAsyncThunk<string | null,
string
, {
rejectValue: string | Error,
state: {
  wallet: WalletState,
}
}>(
  'secretKeeper/connect',
  async (address, { getState, rejectWithValue }) => {
    const state = getState();
    const { selectedWalletType } = state.wallet;
    try {
      let user = await getUser(address);
      if (!user?.nonce) {
        user = await postUser(address);
      }
      if (!user) throw new Error('User data required');
      const { nonce, account } = user;
      if (!nonce) throw new Error('Nonce required');
      if (!account) throw new Error('Account required');
      const signature = await createSignature(selectedWalletType, nonce, account);
      const authData = await auth(account, signature);
      return authData?.accessToken ?? null;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getStorage = createAsyncThunk<StoragePayloadData | undefined, undefined, {
  rejectValue: string | Error,
  state: {
    secretKeeper: SecretKeeperState,
  }
  }>(
    'secretKeeper/getStorage',
    async (_, { getState, rejectWithValue }) => {
      const state = getState();
      const { accessToken } = state.secretKeeper;
      try {
        if (accessToken) {
          const data = await getDataFromStorage(accessToken);
          return data;
        }
        return undefined;
      } catch (err: any) {
        return rejectWithValue(err);
      }
    },
  );