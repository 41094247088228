// eslint-disable-next-line @typescript-eslint/no-var-requires
const bip39 = require('bip39');

export interface GenerateKeysByMnemonicResult {
    privateKeyBase64: string;
    publicKeyBase64: string;
    privateKeyBuffer: Buffer;
    publicKeyBuffer: Buffer;
    mnemonic: string;
}
export interface GenerateRandomKeysResult {
    privateKeyBuffer: Buffer;
    publicKeyBuffer: Buffer;
    privateKeyBase64: string;
    publicKeyBase64: string;
}

export const generateMnemonic = (): string => bip39.generateMnemonic(256);

export const MAX_LENGTH_BASE64_MNEMONIC = 44; //if key size 256

export const generateKeysByMnemonic = async (mnemonic: string): Promise<GenerateKeysByMnemonicResult> => {
  const eccrypto = (await import('eccrypto')).default;
  const entropy = bip39.mnemonicToEntropy(mnemonic);
  const privateKeyBuffer = Buffer.from(entropy, 'hex');
  const publicKeyBuffer = eccrypto.getPublic(privateKeyBuffer);
  const privateKeyBase64 = privateKeyBuffer.toString('base64');
  const publicKeyBase64 = publicKeyBuffer.toString('base64');
  return {
    privateKeyBase64,
    publicKeyBase64,
    privateKeyBuffer,
    publicKeyBuffer,
    mnemonic,
  };
};

export const validateMnemonic = async (mnemonic: string): Promise<boolean> => {
  if (!mnemonic) return false;
  let validate = bip39.validateMnemonic(mnemonic);
  if (!validate) return validate;
  try {
    await generateKeysByMnemonic(mnemonic);
  } catch (e) {
    validate = false;
  }
  return validate;
};

export const getPublicFromPrivate = async (privateKeyBase64: string): Promise<string> => {
  const eccrypto = (await import('eccrypto')).default;
  const publicKeyBuffer = eccrypto.getPublic(Buffer.from(privateKeyBase64, 'base64'));
  return publicKeyBuffer.toString('base64');
};

export const generateRandomKeys = async (): Promise<GenerateRandomKeysResult> => {
  const eccrypto = (await import('eccrypto')).default;
  const privateKeyBuffer = eccrypto.generatePrivate();
  const publicKeyBuffer = eccrypto.getPublic(privateKeyBuffer);
  const privateKeyBase64 = privateKeyBuffer.toString('base64');
  const publicKeyBase64 = publicKeyBuffer.toString('base64');
  return {
    privateKeyBuffer,
    publicKeyBuffer,
    privateKeyBase64,
    publicKeyBase64,
  };
};