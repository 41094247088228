import Web3 from 'web3';
import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { OrderStatus } from '@super-protocol/sdk-js';
import { trackEvent } from 'lib/features/events/thunks';
import BlockchainConnector from 'connectors/sdk/BlockchainConnector';
import { WalletState } from 'lib/features/wallet/types';
import { AnalyticsOffer } from 'common/utils/analytics';
import { encodingAndDownloadFile } from './helpers';
import { addSuccessNotification, addErrorNotification } from '../notifications';

export const getResult = createAsyncThunk<
  { isFile: boolean, content: string, orderId: string },
  { orderId: string, phrase: string, status?: OrderStatus, encryptedResult?: string, analyticsOffers?: AnalyticsOffer[] },
  { rejectValue: string, dispatch: Dispatch<any> }
>(
  'orderDetails/getResult',
  async ({
    orderId, phrase, status, encryptedResult, analyticsOffers,
  }, { rejectWithValue, dispatch }) => {
    try {
      const { isFile, content } = await encodingAndDownloadFile(orderId, phrase, status, encryptedResult);
      if (isFile) {
        dispatch(addSuccessNotification('File downloaded successfully'));
      }
      dispatch(trackEvent({
        eventType: 'order_result_download',
        property: { result: 'success', order: { orderId, offers: analyticsOffers } },
      }));
      return { isFile, content, orderId };
    } catch (err) {
      const message = (err as Error)?.message;
      dispatch(addErrorNotification(message));
      dispatch(trackEvent({
        eventType: 'order_result_download',
        property: {
          result: 'error',
          order: { orderId, offers: analyticsOffers },
          error: message,
          errorStack: (err as Error)?.stack,
        },
      }));
      return rejectWithValue(message);
    }
  },
);

export const replenishDeposit = createAsyncThunk<
string,
{ orderId: string, amount: number, web3Instance: Web3 },
{ rejectValue: string | Error, state: { wallet: WalletState }, dispatch: Dispatch<any> }
>(
  'orderDetails/replenishDeposit',
  async ({ orderId, amount, web3Instance }, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const { selectedAddress } = state.wallet;
    try {
      await BlockchainConnector.getInstance().replenishOrder({
        orderId,
        amount,
        instance: web3Instance,
        accountAddress: selectedAddress,
      });
      dispatch(addSuccessNotification('Order balance will be replenished in 1-2 minutes'));
      dispatch(trackEvent({
        eventType: 'replenish_deposit',
        property: { result: 'success', order: { orderId } },
      }));
      return orderId;
    } catch (err: any) {
      const { message, stack } = (err as Error) || {};
      dispatch(addErrorNotification(message));
      dispatch(trackEvent({
        eventType: 'replenish_deposit',
        property: {
          result: 'error',
          order: { orderId },
          error: message,
          errorStack: stack,
        },
      }));
      return rejectWithValue((err as Error)?.message);
    }
  },
);

export const cancelOrder = createAsyncThunk<
string,
{ orderId: string, web3Instance: Web3 },
{ rejectValue: string | Error, state: { wallet: WalletState }, dispatch: Dispatch<any> }
>(
  'orderDetails/cancelOrder',
  async ({ orderId, web3Instance }, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const { selectedAddress } = state.wallet;
    try {
      await BlockchainConnector.getInstance().cancelOrder({
        orderId,
        instance: web3Instance,
        accountAddress: selectedAddress,
      });
      dispatch(addSuccessNotification('Order cancellation process started'));
      return orderId;
    } catch (err: any) {
      dispatch(addErrorNotification((err as Error)?.message));
      return rejectWithValue((err as Error)?.message);
    }
  },
);
