import {
  TeeOffersAndSLots, Offer,
} from 'generated/types';

export enum OffersPages {
  solutions = 'solutions',
  data = 'data',
  storage = 'storage',
  compute = 'compute',
}

export type Node = Offer | TeeOffersAndSLots;