import { BG_LIGHT, BG_DARK } from 'common/constants';
import { Theme } from 'uikit/common/types';

export const setBodyTheme = (theme: Theme) => {
  const body = document?.querySelector('body');
  if (body) {
    const removeClass = theme === Theme.dark ? Theme.light : Theme.dark;
    body.classList.remove(removeClass);
    body.classList.add(theme);
    body.setAttribute('data-theme', theme);
  }
  const html = document?.querySelector('html');
  if (html) {
    html.setAttribute('style', `background: ${theme === Theme.dark ? BG_DARK : BG_LIGHT}`);
  }
};
