import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { Theme } from 'uikit/common/types';
import { getBroatcastActions } from 'lib/features/helpers';
import { ThemeState } from './types';
import { Storage } from '../types';

const initialState: ThemeState = {
  theme: Theme.dark,
};

export const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateTheme(state, action: PayloadAction<Theme | null | undefined>) {
      if (!action.payload) return;
      state.theme = action.payload;
    },
  },
  selectors: {
    themeSelector: (state) => state.theme,
  },
});

export const { updateTheme } = theme.actions;

export const { themeSelector } = theme.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'theme', storage, whitelist: ['theme'],
}, theme.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(theme);
